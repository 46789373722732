<template>
  <b-form v-on:submit.prevent="save">
    <div class="d-flex flex-lg-row flex-column mt-5">
      <div class="flex-grow-1 mr-5">
        <!-- begin: Name    -->
        <b-form-group
          :label="$t('GROUP.GENERAL.NAME')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-input
            v-model="form.name"
            :state="validateState('name')"
          ></b-form-input>
        </b-form-group>
        <!-- end: Name    -->

        <!-- begin: Description    -->
        <b-form-group
          :label="$t('GROUP.GENERAL.DESCRIPTION')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-textarea
            v-model="form.description"
            :state="validateState('description')"
          ></b-form-textarea>
        </b-form-group>
        <!-- end: Description    -->

        <b-form-group>
          <b-form-checkbox
            v-model="form.disable_late"
            value="1"
            unchecked-value="0"
            :state="validateState('disable_late')"
          >
            Disable late
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

export default {
  name: "EmployeeGroupEditForm",
  mixins: [validationMixin],
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        disable_late: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      disable_late: {
        required,
      },
    },
  },
  beforeMount() {
    const group = this.getGroupInformation;
    console.log("Group is", group);

    this.form.name = group.name;
    this.form.description = group.description;
    this.form.id = this.getGroupInformation.id;
    this.form.disable_late = this.getGroupInformation.disable_late;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Employee Group Form submission");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const formBody = {
        name: this.form.name,
        description: this.form.description,
        allow_late: this.form.disable_late === "0" ? 1 : 0,
        id: this.form.id,
      };

      this.$store
        .dispatch("updateEmployeeGroupForm", formBody)
        .then(() => {
          // Success
          this.$emit("success", true);
          this.$bvModal.msgBoxOk(
            this.$t("GROUP.GENERAL.FORM.SUCCESS", {
              group: this.getGroupInformation.name,
            }),
            {
              title: this.$t("GROUP.GENERAL.FORM.SUCCESS_TITLE"),
            }
          );
        })
        .catch(() => {
          // Display failed
          this.$bvModal.msgBoxOk(
            this.$t("GROUP.GENERAL.FORM.FAILED", {
              group: this.getGroupInformation.name,
            }),
            {
              okVariant: "danger",
              title: this.$t("GROUP.GENERAL.FORM.FAILED_TITLE"),
            }
          );
        });
    },
  },
  computed: {
    ...mapState({
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupForm.isBusy,
    }),
    ...mapGetters([
      "getGroupInformation",
      "getHeldEmpGroupSchedule",
      "checkUpdateEmpGroupScheduleEvent",
    ]),
  },
  watch: {
    checkUpdateEmpGroupScheduleEvent(newState, oldState) {
      console.log("Component", newState, oldState);
      this.save();
    },
  },
};
</script>

<style scoped></style>
