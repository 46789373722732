var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.name,"id":"group-membership-modal","lazy":"","busy":_vm.isBusy,"size":"xl"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [(_vm.tabIndex === 0)?_c('b-button',{class:{
        'spinner spinner-light spinner-right': _vm.isBusy === true,
      },attrs:{"variant":"secondary","disabled":_vm.isEmptyList},on:{"click":_vm.destroy}},[_vm._v("Delete ")]):_vm._e(),(_vm.tabIndex === 1)?_c('b-button',{class:{
        'spinner spinner-light spinner-right': _vm.isBusy === true,
      },attrs:{"variant":"primary","disabled":_vm.isEmptyList},on:{"click":_vm.save}},[_vm._v("Save ")]):_vm._e(),_c('b-button',{staticClass:"btn-hover-danger",attrs:{"disabled":_vm.isBusy,"variant":"light"},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t("BUTTON.CANCEL"))+" ")])]}}])},[_c('b-tabs',{attrs:{"pills":"","nav-class":"nav-primary"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"lazy":"","title":"In Group"}},[_c('GroupMembershipTable')],1),_c('b-tab',{attrs:{"lazy":"","title":"Available"}},[_c('GroupMembershipAvailableTable')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }