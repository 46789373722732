<template>
  <div>
    <EmployeeGroupTableControl class="mb-5" />

    <AppPageCard>
      <template #card-title>
        {{ $t("GROUP.EMPLOYEEGROUP") }}
      </template>

      <template #card-toolbar>
        <!-- begin:: toolbar      -->
        <div>
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="refresh"
            v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
          >
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
            </span>
          </b-button>
          <b-button
            variant="primary"
            class="text-white"
            v-b-toggle.employee-group-sidebar
          >
            {{ $t("EMPLOYEE.BUTTON") }}
          </b-button>
        </div>
        <!-- end:: toolbar      -->
      </template>

      <!-- begin:: sidebar      -->
      <b-sidebar
        ref="employee-group-sidebar-ref"
        id="employee-group-sidebar"
        backdrop
        right
        lazy
        :title="$t('GROUP.CREATEGROUP')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5 mb-5 pb-5">
          <EmployeeGroupPageForm
            v-on:success="closeForm"
          ></EmployeeGroupPageForm>
        </div>
      </b-sidebar>

      <!-- end:: sidebar      -->
      <EmployeeGroupPageTable :reload="reload"></EmployeeGroupPageTable>
    </AppPageCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmployeeGroupPageTable from "@/modules/company/components/group/EmployeeGroupPageTable";
import EmployeeGroupPageForm from "@/modules/company/components/group/EmployeeGroupPageForm";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import EmployeeGroupTableControl from "@/modules/company/components/group/control/EmployeeGroupTableControl";

export default {
  name: "EmployeeGroupPage",
  data: function () {
    return {
      reload: false,
    };
  },
  components: {
    EmployeeGroupTableControl,
    AppPageCard,
    EmployeeGroupPageForm,
    EmployeeGroupPageTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.GROUP"), route: "/company/employee/group" },
    ]);
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
    closeForm() {
      const sideBar = this.$refs["employee-group-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
