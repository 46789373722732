<template>
  <AppTableControl
    @query-debounced="searchByName"
    @reset-filters="resetFilters"
    @export-excel-clicked="
      $store.dispatch('downloadEmployeeGroupReport', { type_id: 1 })
    "
    @export-pdf-clicked="
      $store.dispatch('downloadEmployeeGroupReport', { type_id: 2 })
    "
  >
    <template #filters>
      <div class="d-flex">
        <b-form-group :label="$t('EMPLOYEETABLE.COUNT')">
          <b-form-input
            v-model="count"
            type="number"
            @input="inputCount"
          ></b-form-input>
        </b-form-group>
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "EmployeeGroupTableControl",
  components: { AppTableControl },
  data() {
    return {
      count: null,
    };
  },
  methods: {
    /**
     * Search by name parameter.
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeEmployeeGroupListNameFilter", {
        name: nameQuery,
      });

      this.$store.dispatch("getEmployeeGroup");
    },

    /**
     * Query
     * @param countQuery
     */
    inputCount(countQuery) {
      console.log(countQuery);

      this.$store.dispatch("changeEmployeeGroupListCountFilter", {
        count: countQuery,
      });

      this.$store.dispatch("getEmployeeGroup");
    },

    /**
     * Reset all filters
     */
    resetFilters() {
      this.count = null;

      this.$store.dispatch("changeEmployeeGroupListCountFilter", {
        count: null,
      });

      this.$store.dispatch("getEmployeeGroup");
    },
  },
};
</script>

<style scoped></style>
