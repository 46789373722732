var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"employee-group-edit-modal","footer-bg-variant":"light","title":_vm.groupName,"size":_vm.size},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"btn-hover-danger",attrs:{"variant":"light"},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t("BUTTON.CANCEL"))+" ")]),(_vm.tab === 0)?_c('b-button',{class:{
        'spinner spinner-light spinner-right': _vm.getHeldEmpEditGroup,
      },attrs:{"variant":"primary"},on:{"click":_vm.updateGroup}},[_vm._v(" "+_vm._s(_vm.$t("GROUP.UPDATE"))+" ")]):_vm._e(),(_vm.tab === 1)?_c('b-button',{class:{
        'spinner spinner-light spinner-right': _vm.getHeldEmpGroupSchedule,
      },attrs:{"variant":"primary"},on:{"click":_vm.saveSchedule}},[_vm._v(_vm._s(_vm.$t("GROUP.SAVE"))+" ")]):_vm._e()]}}])},[_c('b-tabs',{attrs:{"pills":"","nav-class":"nav-primary"},on:{"activate-tab":_vm.changeModalSize},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('b-tab',{attrs:{"title":this.$t('GROUP.SCHEDULE.TABGENERAL'),"lazy":""}},[_c('EmployeeGroupEditForm',{staticClass:"m-2 mt-3 p-2"})],1),_c('b-tab',{attrs:{"title":this.$t('GROUP.SCHEDULE.BASE'),"lazy":""}},[_c('EmployeeGroupSchedule',{staticClass:"m-2 mt-3 p-2"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }