<template>
  <div class="d-flex flex-column">
    <EmployeeGroupScheduleControl></EmployeeGroupScheduleControl>
    <EmployeeGroupScheduleForm></EmployeeGroupScheduleForm>
  </div>
</template>

<script>
import EmployeeGroupScheduleForm from "@/modules/company/components/group/schedule/EmployeeGroupScheduleForm";
import EmployeeGroupScheduleControl from "@/modules/company/components/group/schedule/EmployeeGroupScheduleControl";
export default {
  name: "EmployeeGroupSchedule",
  components: { EmployeeGroupScheduleControl, EmployeeGroupScheduleForm },
};
</script>

<style scoped></style>
