<template>
  <!-- begin: GroupMembershipTable Container  -->
  <div class="d-flex flex-column">
    <b-table
      :busy="getMembershipListBusy"
      :items="getMembershipList"
      :fields="fields"
      responsive
      show-empty
      hover
      @row-clicked="handleCheckBox"
      table-class="table-head-custom table-vertical-center table-head-bg mt-5"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <div class="d-flex mt-1 mb-2">
          <b-form-checkbox
            size="lg"
            v-model="employeeChecked"
            :value="data.item.id"
            @change="handleCheckBox(data.item, false)"
            class="h6 mr-3"
          ></b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary
          :item="generateSummary(data.item)"
        ></CommonEmployeeSummary>
      </template>

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="Empty Group"
          text-bottom="Please add employees to this group in order for the list to appear."
        ></AppEmptyList>
      </template>
    </b-table>

    <b-pagination
      pills
      size="lg"
      class="align-self-end"
      v-model="getMembershipPagination.current_page"
      :total-rows="getMembershipPagination.total"
      :per-page="getMembershipPagination.per_page"
      @change="change"
    >
    </b-pagination>
  </div>
  <!-- begin: GroupMembershipTable Container -->
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "GroupMembershipTable",
  components: { CommonEmployeeSummary, AppEmptyList, AppLoader },
  data: function () {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: this.$t("EMPLOYEE.HEADER") },
      ],
      employeeChecked: [],
    };
  },

  created() {
    // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "setGroupMembershipSuccess") {
        console.log("Observing setGroupMembershipSuccess");
        this.$store.dispatch("fetchGroupMembers");
      }
    });
  },

  methods: {
    /**
     * Change page.
     *
     * @param page
     */
    change(page) {
      console.log("Current Page", this.meta.current_page);
      this.meta.current_page = page;
      this.$store.dispatch("fetchGroupMembers");
    },

    /**
     * Generate summary
     *
     * @param item
     */
    generateSummary(item) {
      console.log("Employee :", item);

      return {
        image_url: item.image_url,
        name: `${item.first_name} ${item.last_name}`,
        code: item.unique_code,
        email: item.email,
      };
    },

    /**
     * Handles employee on-click support.
     *
     * @param row
     * @param checkExists
     */
    handleCheckBox(row, checkExists) {
      console.log(row);
      console.log(this.employeeChecked);

      const exists = this.employeeChecked.includes(row.id);
      console.log("Row exists:", row.id, exists);

      if (exists && checkExists) {
        this.employeeChecked = this.employeeChecked.filter(function (value) {
          console.log("Checked value: ", value, row.id);
          const matched = value === row.id;
          console.log("Matched? ", matched);

          return value !== row.id;
        });
      } else this.employeeChecked.push(row.id);

      this.$store.dispatch("selectEmployeeGroupMemberModification", {
        id: row.id,
      });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    this.$store.dispatch("fetchGroupMembers");
  },
  computed: {
    ...mapGetters([
      "getMembershipListBusy",
      "getMembershipList",
      "getMembershipPagination",
    ]),
  },
};
</script>

<style scoped></style>
